import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../../layouts/default"

import Breadcrumb from "../../components/breadcrumb"
import Paragraph from "../../components/paragraph"
import Heading from "../../components/heading"
import Grid from "../../components/grid"
import Image from "../../components/image"
import PageTitle from "../../components/page-title"
import Seo from "../../components/seo"
import Stack from "../../components/stack"

const Mediaguide = () => {
  const data = useStaticQuery(graphql`
    query MediaguideGebaerdeQuery {
      teaser1: file(relativePath: { eq: "mediaguide/gebaerdensprache-2.jpg" }) {
        ...largeImage
      }
      teaser2: file(relativePath: { eq: "mediaguide/gebaerdensprache-3.jpg" }) {
        ...largeImage
      }
      poster: file(
        relativePath: {
          eq: "dauerausstellung/07-harte-fuegungen/poster-pindar-01.jpg"
        }
      ) {
        ...largeImage
      }
    }
  `)

  return (
    <Layout backdrop="mediaguide-gebaerdensprache">
      <Seo
        title="Media-Guide in Gebärdensprache"
        description="In 15 Videos in Deutscher Gebärdensprache erfahren Sie mehr über die Ausstellungsräume und -themen."
      />
      <Stack>
        <Breadcrumb
          items={[
            {
              title: "Media-Guide",
              link: "/mediaguide",
            },
            {
              title: "Gebärdensprache",
              link: "/mediaguide/gebaerdensprache",
            },
          ]}
        />
        <PageTitle>Media-Guide in Gebärdensprache</PageTitle>
        <Paragraph dropcap={true}>
          Im Media-Guide zur Dauerausstellung gibt es eine Führung in
          Gebärdensprache. Sie können sich den Media-Guide im Museum ausleihen
          oder die App auf Ihr eigenes Handy herunterladen.
        </Paragraph>
        <Grid columns={[1, 2]} space={"px"}>
          <Image
            image={data.teaser1.childImageSharp.gatsbyImageData}
            alt="Eine Besucherin beim Verwenden des Media-Guides vor einer Vitrine"
          />
          <Image
            image={data.teaser2.childImageSharp.gatsbyImageData}
            alt="Eine Besucherin hört sich die Audioinhalte zu Hölderlins Schreibtisch an"
          />
        </Grid>
        <Stack space={6}>
          <Heading as="h2" level={3}>
            Mit Videos in Gebärdensprache durch die Ausstellung
          </Heading>
          <Paragraph>
            Wenn Sie auf dem Bildschirm ›Gebärdensprache‹ auswählen, erhalten
            Sie eine Liste mit allen Inhalten in Gebärdensprache. Daraus können
            Sie sich eine eigene Führung durch die Ausstellung zusammenstellen:
            Sie können sich nach und nach durch alle Ausstellungsräume leiten
            lassen. Oder nur die Themen auswählen, die Sie ganz besonders
            interessieren.
          </Paragraph>
        </Stack>
        {/* <VideoPlayer
          src="pindar-01"
          poster={data.poster.childImageSharp.fluid.src}
        /> */}
      </Stack>
    </Layout>
  )
}

export default Mediaguide
